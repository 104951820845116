import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

import { Layout } from "../components/common"
import media, { sizes } from "../styles/media"
import SEO from "../components/common/SEO"
import { isNotPlaceholder } from "../utils/common/content"

const AboutHero = styled.div`
  height: 100vh;
`

const AboutHeroImage = styled(Img)`
  height: 100%;
`

const HeroCaption = styled.div`
  width: 85%;
  margin: 12px auto 56px auto;

  h1 {
    font-family: StanleyRegular, serif;
    font-size: 30px;
    font-weight: normal;
    line-height: 36px;
    margin: 0;

    ${media.tablet`
      color: ${props => props.theme.white};
      font-size: 45px;
      line-height: 52px;
    `}
  }

  ${media.tablet`
    position: absolute;
    left: 36px;
    bottom: 45px;
    margin: 0;
    width: 75%;
  `}
`

const CopyAndTextBlockWrapper = styled.div`
  display: ${props => (props.show ? "block" : "none")};
  margin: auto;
  width: 85%;

  ${media.tablet`
    margin-bottom: 90px;
    width: 70%;
  `}
`

const Body = styled.div`
  margin-bottom: 40px;

  em {
    display: flex;
    text-indent: 0px;
    margin-top: -32px;
    margin-bottom: 32px;
    font-style: normal;
    color: ${props => props.theme.lightGrey3};
    font-size: 16px;
    line-height: 20px;
  }

  p {
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 32px;
    margin-top: 0;

    ${media.tablet`
      font-size: 20px;
    `}
  }

  .image-wrapper {
    align-content: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-indent: 0px;
    width: 100%;

    margin: 40px 0;

    img {
      max-height: 24vh;
      margin: 0 18px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      ${media.tablet`
        max-height: 50vh;
        max-width: 70vw;
      `}
    }
  }

  ${media.tablet`
    margin-top: 90px;
  `}
`

export default ({ data }) => {
  const aboutPageContent = data.allContentfulAboutPage.edges.filter(
    isNotPlaceholder
  )[0].node

  const heroCaption = aboutPageContent.heroCaption.heroCaption
  const firstCopyBlock = aboutPageContent.body.childMarkdownRemark.html

  const imageUrl =
    aboutPageContent.aboutPageMetaContent &&
    aboutPageContent.aboutPageMetaContent.metaImage
      ? aboutPageContent.aboutPageMetaContent.metaImage.file.url
      : undefined

  const twitterImageUrl =
    aboutPageContent.aboutPageMetaContent &&
    aboutPageContent.aboutPageMetaContent.metaImageTwitter
      ? aboutPageContent.aboutPageMetaContent.metaImageTwitter.file.url
      : undefined

  const [isMounted, setIsMounted] = useState(false)

  // If a mobile asset is provided, include it for devices below our tablet breakpoint,
  // otherwise just provide the default asset
  const heroSources = aboutPageContent.mobileHeroImage
    ? [
        aboutPageContent.mobileHeroImage.fluid,
        {
          ...aboutPageContent.heroImage.fluid,
          media: `(min-width: ${sizes.tablet}px)`,
        },
      ]
    : [aboutPageContent.heroImage.fluid]

  useEffect(() => {
    Array.from(document.querySelectorAll(".about-body p > img")).forEach(node =>
      node.parentElement.classList.add("image-wrapper")
    )

    setIsMounted(true)
  }, [firstCopyBlock])

  return (
    <Layout hasHero>
      <SEO
        title="Columbia Artists - About"
        path="/about"
        description={
          aboutPageContent.aboutPageMetaContent &&
          aboutPageContent.aboutPageMetaContent.metaImage
            ? aboutPageContent.aboutPageMetaContent.metaDescription
                .metaDescription
            : undefined
        }
        imageUrl={imageUrl && "https:" + imageUrl}
        twitterImageUrl={twitterImageUrl && "https:" + twitterImageUrl}
      />
      <AboutHero>
        <AboutHeroImage alt="About Columbia Artists" fluid={heroSources} />
      </AboutHero>
      <HeroCaption>
        <h1 dangerouslySetInnerHTML={{ __html: heroCaption }} />
      </HeroCaption>
      <CopyAndTextBlockWrapper show={isMounted}>
        <Body
          className="about-body"
          dangerouslySetInnerHTML={{ __html: firstCopyBlock }}
        />
      </CopyAndTextBlockWrapper>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    allContentfulAboutPage {
      edges {
        node {
          title
          aboutPageMetaContent {
            metaDescription {
              metaDescription
            }
            metaImageTwitter {
              file {
                url
              }
            }
            metaImage {
              file {
                url
              }
            }
          }
          heroImage {
            fluid(maxWidth: 1600, quality: 70) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          mobileHeroImage {
            fluid(maxHeight: 812, quality: 99) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          heroCaption {
            heroCaption
          }
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
